import React from "react"
import Image from "gatsby-image"

import Layout from "../layouts/layout"
import SEO from "../components/seo"

const TermsOfServicePage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`;
    return(
        <Layout location={location} title={siteTitle}>
            <section className="uni-section">
                <div className="uni-container public-container">
                    <div>
                        <h2>Terms Of Service</h2>
                        <p className="pp-p">
                            This document (the “Agreement”) is a legally binding agreement between you and Sunset Health Inc, a company registered in the United States, 
                            that governs your use of the online and mobile services associated with Sunset Health, including but not limited to, www.sunset.health and all 
                            associated subdomains (the “Website”), and the Sunset Health mobile application (“App”).
                        </p>
                        <p className="pp-p">
                            For ease of reference, all of the features and functionality of both the App and the Website, together with all of its content (whether accessible 
                            wirelessly, electronically or downloadable for printing), shall be referred to herein as the “System”.
                        </p>
                        <p className="pp-p">
                            One component of the System is the self-help isolation and burnout mental wellness improvement program; which may also include associated expert articles, 
                            tools, and online community (the “Sunset Health Service”). All terms governing the System also apply to the Sunset Health Service. The System and the Sunset 
                            Health Service are both owned and managed by Sunset Health.
                        </p>
                        <p className="pp-p">
                            Please note that you must read and agree to the terms and conditions of this Agreement before you use the System and/or the Sunset Health Service. If you do 
                            not agree to the terms and conditions of the Agreement, you may NOT use the System nor may you use the Sunset Health Service.The terms “we”, “us”, “our” and “ours” 
                            when used in these terms mean Sunset Health, which includes any parent company, subsidiaries, branches or affiliates under common ownership or control of Sunset Health. 
                            The terms “you”, “your” and “yours” when used in these terms mean any user of the System.
                        </p>
                    </div>
                    <div>
                        <h4 className="emp-h4  emp-font-bold">1. Contract formation</h4>
                        <p className="pp-p">
                            By using the System you confirm that you are 18 years of age or more, that any registration information that you submit to Sunset Health is true, accurate and complete, 
                            that you will update such information in order to keep it current, that you have read and agree to the terms and conditions contained in this Agreement, our Privacy Policy 
                            and our Posting Policy (both of which are hereby incorporated into this Agreement) and that you shall be legally bound by such terms subject to all applicable laws and regulations.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">2. Understanding Sunset Health</h4>
                        <p className="pp-p">
                            You acknowledge and agree that the Sunset Health Service is a personalized self-help system designed to help you improve your own mental wellness regarding isolation and burnout, 
                            and that if you choose to access the Sunset Health Service you are solely responsible for deciding which of the suggested techniques you put into practice and how to apply those techniques.
                        </p>
                        <p className="pp-p">
                            You also acknowledge and agree that the Sunset Health Service is not intended to diagnose, treat or otherwise address any medical or mental health problem, concern, or disease, and the material 
                            on the System, whether posted by Sunset Health employees, or other users, is provided for your information and not as medical advice and should not be seen as a replacement for consultation with 
                            a doctor or other qualified healthcare or mental health professional. If you receive advice from a doctor or other qualified medical or mental health professional which conflicts with anything contained 
                            in the System then the former should take precedence.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">3. Grant of right of use</h4>
                        <p className="pp-p">
                            Certain content and features of the System are only available to individuals who pay an applicable fee or have the fee paid for by their employer or insurance plan (“Paid for Services”). 
                            Unless otherwise specified, we grant you a limited, non-exclusive, non-transferable, revocable right to make personal non-commercial use only of that content and those features of the System 
                            and Sunset Health Service in respect of which you have paid all applicable fees and charges, provided that you comply fully with the provisions of this Agreement.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">4. Modifying the Service and Termination</h4>
                        <p className="pp-p">
                            Sunset Health is always striving to improve the Service and bring you additional functionality that you will find engaging and useful. This means we may add new product features or enhancements 
                            from time to time as well as remove some features, and if these actions do not materially affect your rights or obligations, we may not provide you with notice before taking them. We may even 
                            suspend the Service entirely, in which event we will notify you in advance unless extenuating circumstances, such as safety or security concerns, prevent us from doing so.
                        </p>
                        <p className="pp-p">
                            You may terminate your account at any time, for any reason, by contacting us at support@sunset.health and requesting an account termination, however if you use a third party payment account, 
                            you will need to manage in app purchases through such account (e.g., iTunes) to avoid additional billing.
                        </p>
                        <p className="pp-p">
                            Sunset Health may terminate your account at any time without notice if it believes that you have violated this Agreement. Upon such termination, you will not be entitled to any refund for purchases.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">5. Purchases</h4>
                        <p>
                            Generally. From time to time, Sunset Health may offer products and services for purchase (“in app purchases”) through iTunes, carrier billing, Sunset Health direct billing or other payment platforms 
                            authorized by Sunset Health. If you choose to make an in app purchase, you will be prompted to confirm your purchase with the applicable payment provider, and your method of payment (be it your card or 
                            a third party account such as iTunes) (your “Payment Method”) will be charged at the prices displayed to you for the service(s) you’ve selected as well as any sales or similar taxes that may be imposed 
                            on your payments, and you authorize Sunset Health or the third party account, as applicable, to charge you.
                        </p>
                        <p className="pp-p">
                            Auto-Renewal. If you purchase an auto-recurring periodic subscription, your Payment Method will continue to be billed for the subscription until you cancel. After your initial subscription commitment period, 
                            and again after any subsequent subscription period, your subscription will automatically continue for an additional equivalent period, at the price you agreed to when subscribing. If you do not wish your 
                            subscription to renew automatically, or if you want to change or terminate your subscription, you will need to log in to your third party account and follow instructions to cancel your subscription, even 
                            if you have otherwise deleted your account with us or if you have deleted the Sunset Health application from your device. Deleting your account on Sunset Health or deleting the Sunset Health application from 
                            your device does not cancel your subscription; Sunset Health will retain all funds charged to your Payment Method until you cancel your subscription on Sunset Health or the third party account, as applicable. 
                            If you cancel your subscription, you may use your subscription until the end of your then-current subscription term, and your subscription will not be renewed after your then-current term expires.
                        </p>
                        <p className="pp-p">
                            Additional Terms that apply if you pay Sunset Health directly with your Payment Method. If you pay Sunset Health directly, Sunset Health may correct any billing errors or mistakes that it makes even if it 
                            has already requested or received payment. If you initiate a chargeback or otherwise reverse a payment made with your Payment Method, Sunset Health may terminate your account immediately in its sole discretion.
                        </p>
                        <p className="pp-p">
                            Refunds. Generally, all charges for purchases are nonrefundable, and there are no refunds or credits for partially used periods. We may make an exception if a refund for a subscription offering is requested 
                            within fourteen days of the transaction date, or if the laws applicable in your jurisdiction provide for refunds.
                        </p>
                        <p className="pp-p">
                            For subscribers residing in Arizona, California, Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina, Ohio and Wisconsin, the terms below apply:
                        </p>
                        <p className="pp-p">
                            You may cancel your subscription, without penalty or obligation, at any time prior to midnight of the third business day following the date you subscribed. In the event that you die before the end of your 
                            subscription period, your estate shall be entitled to a refund of that portion of any payment you had made for your subscription which is allocable to the period after your death. In the event that you 
                            become disabled (such that you are unable to use the services of Sunset Health) before the end of your subscription period, you shall be entitled to a refund of that portion of any payment you had made 
                            for your subscription which is allocable to the period after your disability by providing the company notice in the same manner as you request a refund as described below.
                        </p>
                        <p className="pp-p">
                            <span style={{"white-space": "pre-line"}}>To request a refund:</span><br/>
                            If you subscribed using your Apple ID, refunds are handled by Apple, not Sunset Health. To request a refund, go to iTunes, click on your Apple ID, select “Purchase history,” find the transaction and hit 
                            “Report Problem”. You can also submit a request at //getsupport.apple.com.   
                        </p>
                        <h4 className="emp-h4  emp-font-bold">6. Changes to the Agreement</h4>
                        <p className="pp-p">
                            Sunset Health reserves the right to vary this Agreement from time to time by amending this page. Any material amendments intending to bind an existing user of the System shall become effective after notification 
                            has been displayed for 7 days on such user’s account settings page.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">7. Privacy</h4>
                        <p className="pp-p">
                            We process information about you in accordance with our Privacy Policy. By using the System, you consent to such processing and you warrant that all data provided by you is accurate.
                        </p>
                        <p className="pp-p">
                            In order to access the Paid for Services, you may be required to register personal information and establish unique usernames and passwords. You are responsible for maintaining the 
                            confidentiality of any passwords, and are fully responsible and or liable for all activities that occur under your password and username, whether by you or by anyone else using your 
                            identity. Should your password be compromised by breaches of security such as, but not limited to, loss, theft and unauthorized disclosure, it is your responsibility to immediately 
                            notify us of the need to change or deactivate the password. Until we are so notified you will remain liable for any unauthorized use of your account.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">8. Intellectual Property Rights</h4>
                        <p className="pp-p">
                            The layout, design, content and graphics on the System and the Sunset Health Service are protected by US and other international copyright laws. Unless expressly permitted in writing 
                            and other than printing a small proportion of content or displaying this on your screen or both, (strictly for your personal non-commercial use), no part of the System may be reproduced 
                            or stored in any medium, including but not limited to a retrieval system, or transmitted, in any form or by any means (electronic, mechanical, photocopying, recording, broadcasting), 
                            nor shown in public. You may not create any derivative work or make any other adaptation, without our prior written consent. You must not modify the copies you have displayed or printed 
                            in any way and you may not use any illustrations, photographs, videos or audio sequences or any graphics separately from any accompanying text.
                        </p>
                        <p className="pp-p">
                            If you print off or download any material from the System in breach of these terms of use, your right to use the System and the Sunset Health Service will cease immediately and you must 
                            at our option return or destroy any copies of the materials you have made. All rights not expressly granted in these terms or any express written license, are reserved. For all other uses 
                            of our content or images you must contact us and obtain our prior written permission.The Sunset Health logo and the name “Sunset Health” are trademarks owned by Sunset Health. Any use, 
                            including, but not limited to, framing, meta tags or other text using the Sunset Health logo or the Sunset Health name or other trademarks displayed, is strictly prohibited without our 
                            prior written consent.Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">9. Prohibited uses</h4>
                        <p className="pp-p">You agree that you will not use the System to:</p>
                        <ul className="emp-list">
                            <li>
                                Upload, post, email or otherwise transmit any content or materials that are unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, 
                                invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable, at our sole and absolute discretion;
                            </li>
                            <li>
                                Harm minors in any way, or solicit or otherwise attempt to gain any information from a minor;
                            </li>
                            <li>
                                Impersonate any person or entity, including, but not limited to, any user of this System, a director, officer, employee, shareholder, agent or representative of Sunset Health 
                                or our affiliates, or any other person or entity, or falsely state or otherwise misrepresent your affiliation with Sunset Health, our affiliates or any other person or entity;
                            </li>
                            <li>
                                Forge headers or otherwise manipulate identifiers in order to disguise the origin of any Postings or other materials transmitted to or through the System;
                            </li>
                            <li>
                                Upload, post, email or otherwise transmit any materials that are not your own, or that you do not have a right to upload, post, email or otherwise transmit under any law or under 
                                contractual or fiduciary relationships (such as insider information, proprietary and confidential information learned or disclosed as part of employment relationships or under 
                                nondisclosure agreements);
                            </li>
                            <li>
                                Upload, post, email or otherwise transmit any content or other materials that infringe upon any patent, trademark, trade secret, copyright, right of privacy or publicity or other 
                                proprietary rights of any party;
                            </li>
                            <li>
                                Upload, post, email or otherwise transmit any unsolicited or unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other 
                                form of commercial solicitation except in the areas, if any that are specifically designated for such purpose;
                            </li>
                            <li>
                                Upload, post, email or otherwise transmit any content or other materials that contain software viruses or any other computer code, files or programs designed to interrupt, destroy 
                                or limit the functionality of any computer software or hardware or telecommunications equipment;
                            </li>
                            <li>
                                Interfere with or disrupt the System or servers or networks connected to the System, or disobey any requirements, procedures, policies or regulations of networks connected to the System;
                            </li>
                            <li>Intentionally or unintentionally violate any applicable laws and/or regulations;</li>
                            <li>“Stalk” or otherwise harass another user of the System and/or any employee of Sunset Health;</li>
                            <li>
                                Solicit, collect or post personal data or attempt to solicit, collect or post personal data about other users of the Website from the Website (including user names or passwords) or about 
                                any other third party; and/or
                            </li>
                            <li>Access or attempt to access another user’s account without his or her consent.</li>
                            <li>
                                Knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar 
                                computer code designed to adversely affect the operation of any computer software or hardware.
                            </li>
                        </ul>
                        <p className="pp-p">You also agree not to:</p>
                        <ul className="emp-list">
                            <li>
                                reproduce, duplicate, copy or re-sell any part of the System in contravention of the provisions of this Agreement.
                            </li>
                            <li>resell access to the System.</li>
                            <li>frame the System or any part of it.</li>
                            <li>copy any material obtained from the System to peer to peer networks or other web sites or web services.</li>
                            <li>
                                access without authority, interfere with, damage or disrupt any part of the System; any equipment or network on which the System is stored; any software used in the provision of the System; 
                                or any equipment or network or software owned or used by any third party.
                            </li>
                        </ul>
                        <h4 className="emp-h4  emp-font-bold">10.  Breach of the Agreement</h4>
                        <p className="pp-p">
                            We will determine, in our absolute discretion, whether there has been a breach of this Agreement through your use of the System. When a breach of this Agreement has occurred, we may take such 
                            action as we deem appropriate, which may include all or any of the following actions:
                        </p>
                        <ul className="emp-list">
                            <li>Immediate, temporary or permanent withdrawal of your right to use the System and/or the Sunset Health Service.</li>
                            <li>Immediate, temporary or permanent removal of any posting or material uploaded by you to the System and/or the Sunset Health Service.</li>
                            <li>Issue a warning notice to you to immediately cease and/or remedy such breach and following receipt such action should be taken immediately.</li>
                            <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</li>
                            <li>Any further legal action against you.</li>
                        </ul>
                        <p className="pp-p">
                            Disclosure of such information to law enforcement authorities as we reasonably feel is necessary.
                        </p>
                        <p className="pp-p">
                            We exclude liability for actions taken in response to breaches of this Agreement. The possible actions we may take on breach of this Agreement are not limited to those described here, and 
                            we may take any other action we reasonably deem appropriate.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">11. No warranty</h4>
                        <p className="pp-p">
                            The use of the System and Sunset Health Service (including but not limited to their content and features) is at your own risk. The System is provided on an “as is” and “as available” basis. 
                            To the extent permitted by applicable law, Sunset Health gives no warranty, express or implied, as to the quality, content and availability or fitness for a specific purpose of the System or 
                            the Sunset Health Service or to the accuracy of the information contained in any of the materials on the System or the Sunset Health Service. Sunset Health shall not be liable to any person 
                            for any loss or damage of any kind, which may arise, directly or indirectly from the use of or inability to use any of the information contained in any of the materials on the System. 
                            The use of the System and Sunset Health Service (including but not limited to their content and features) is at your own risk. The System is provided on an “as is” and “as available” basis. 
                            To the extent permitted by applicable law, Sunset Health gives no warranty, express or implied, as to the quality, content and availability or fitness for a specific purpose of the System or 
                            the Sunset Health Service or to the accuracy of the information contained in any of the materials on the System or the Sunset Health Service. Sunset Health shall not be liable to any person 
                            for any loss or damage of any kind, which may arise, directly or indirectly from the use of or inability to use any of the information contained in any of the materials on the System. 
                            There is no guarantee of availability of information on the System at any time, nor that it is up to date or error-free. As with any purchase of a product or service through any medium 
                            or in any environment, you should use your judgment and exercise caution where appropriate. No advice or information whether oral or in writing obtained by you from Sunset Health shall 
                            create any warranty on behalf of Sunset Health in this regard. Certain jurisdictions do not allow the exclusion or disclaimer of certain warranties. Accordingly some of the above disclaimers 
                            may not apply to you. Your statutory rights as a consumer, if any, are not affected hereby.There is no guarantee of availability of information on the System at any time, nor that it is up to 
                            date or error-free. As with any purchase of a product or service through any medium or in any environment, you should use your judgment and exercise caution where appropriate. No advice or 
                            information whether oral or in writing obtained by you from Sunset Health shall create any warranty on behalf of Sunset Health in this regard. Certain jurisdictions do not allow the exclusion 
                            or disclaimer of certain warranties. Accordingly some of the above disclaimers may not apply to you. Your statutory rights as a consumer, if any, are not affected hereby.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">12. Limitation of liability</h4>
                        <p className="pp-p">
                            To the fullest extent applicable permitted by applicable laws, Sunset Health, its affiliates, officers, directors, employees, licensors or any third parties exclude liability for any direct, 
                            indirect, incidental, special or consequential damages (including but not limited to any loss of data, service interruption, computer failure or pecuniary loss, loss of income or revenue, 
                            loss of business, loss of profits or contracts, loss of anticipated savings, loss of data, loss of goodwill, wasted management or office time and for any other loss or damage of any kind, 
                            however arising and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable) arising out of the use of or inability to use the System or Sunset 
                            Health Service, even if you have advised Sunset Health about the possibility of such loss, and including any damages resulting therefrom. Commentary and other materials posted on the System 
                            by other users are not intended to amount to advice on which reliance should be placed. We therefore, to the fullest extent permitted by applicable laws, disclaim all liability and responsibility 
                            arising from any reliance placed on such materials by any visitor to the System, or by anyone who may be informed of any of its contents. If any part of this limitation of liability is found to be 
                            invalid or unenforceable for any reason, then the aggregate liability of Sunset Health (including any of its affiliates, service providers and licensors) under such circumstance for liabilities that 
                            otherwise would have been limited, shall not exceed the maximum single purchase price of the Sunset Health Service.Nothing in this provision affects our or our contractors’ liability for death or 
                            personal injury arising from our (or their) negligence nor our (or their) liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter nor any other liability which 
                            cannot be excluded or limited under an applicable law.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">13. Technology limitations and modifications</h4>
                        <p className="pp-p">
                            Sunset Health will make reasonable efforts to keep the System operational. However, certain technical difficulties, maintenance, or any other cause beyond our reasonable control (including 
                            (but not limited to) failure of the internet, natural disaster, labor shortage or dispute, or governmental act) may, from time to time, result in interruptions. Sunset Health reserves the 
                            right at any time and from time to time to modify or discontinue, temporarily or permanently, functions and features of the System with or without notice. You agree not to hold us liable 
                            for any such failure or delay in performance and we agree to make all reasonable efforts to restore the System (or any part thereof) as soon as practicable. Following any disruption within 
                            Sunset Health’s reasonable control we shall extend your access to the Paid for Services for at least the duration for which those Paid for Services were inaccessible on written request to 
                            the below address.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">14. Third parties</h4>
                        <p className="pp-p">
                            We may make software owned or operated by third-party companies available to you. You must only use this software in accordance with the terms and conditions imposed by the third-party provider.
                        </p>
                        <p className="pp-p">
                            Description of or reference to any organization, product, practitioner, publication or link to an external website does not imply endorsement by Sunset Health. Equally the omission of any such 
                            names does not necessarily indicate a lack of endorsement by Sunset Health.
                        </p>
                        <p className="pp-p">
                            Your correspondence or participation in promotions, or business dealings with advertisers found on or through the System, including payment and delivery of related goods or services, and any 
                            other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertisers. You agree that we shall not be responsible or liable for 
                            any direct or indirect loss or damage of any sort, incurred, or alleged to have been incurred, as the result of any such dealings, or as the result of the presence of such advertisers on 
                            the System.Certain hypertext links in this site may lead to other third party websites, which are not under the control of Sunset Health. When you activate any of these you will leave the 
                            System and Sunset Health has no control over, and will accept no responsibility or liability, for the material on any website which is not under the control of Sunset Health. Sunset Health 
                            does not make any representations or give any guarantee or warranties of any kind, expressed, implied or otherwise about the content of on any external website link.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">15. Indemnity</h4>
                        <p className="pp-p">
                            You agree to indemnify and hold Sunset Health and its officers, directors, employees and licensors harmless from any claim or demand (including but not limited to reasonable legal fees) 
                            made by a third party due to or arising out of or related to your violation of the terms and conditions of this Agreement or your violation of the System (and all related materials) or 
                            any applicable laws, regulations or third party rights.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">16. Effective Date</h4>
                        <p className="pp-p">
                            Sunset Health may assign (or otherwise transfer) this Agreement or any part of it without restrictions. You may not assign (or otherwise transfer) this Agreement or any part of it to any third party.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">17. Entire agreement</h4>
                        <p className="pp-p">
                            This Agreement (incorporating these terms and the Privacy Policy and the Posting Policy as may be updated from time to time) constitutes all the terms and conditions agreed upon between you and 
                            Sunset Health and supersede any prior agreements in relation to the subject matter of this Agreement, whether written or oral. Any additional or different terms or conditions in relation to the 
                            subject matter of this Agreement in any written or oral communication from you to Sunset Health are void. You agree and accept that you have not accepted the terms and conditions of this Agreement 
                            in reliance of or to any oral or written representations made by Sunset Health not contained in this Agreement.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">18. Servability and waiver</h4>
                        <p className="pp-p">
                            If any provision of the Agreement is held by a court of competent jurisdiction to be unlawful, void or for any reason unenforceable, such provision shall be changed and interpreted so as to best 
                            accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of the Agreement shall remain in full force and effect.If we fail to insist 
                            that you perform any of your obligations under these terms, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you 
                            and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any 
                            later default by you.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">19. Term and termination</h4>
                        <p className="pp-p">
                            This Agreement is between you and us only and will become effective when you start using the System and will remain effective until terminated by you or Sunset Health. Sunset Health reserves the 
                            right to terminate this Agreement or suspend your Sunset Health account at any time in case of unauthorized, or suspected unauthorized use of the System whether in contravention of this Agreement 
                            or otherwise. If Sunset Health terminates this Agreement, or suspends your Sunset Health account, for any of the reasons set out in this section, Sunset Health shall have no liability or responsibility 
                            to you whatsoever, and Sunset Health shall not refund any amounts that you have paid.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">20. Governing law and disputes</h4>
                        <p className="pp-p">
                            The System is controlled by Sunset Health from its offices in the United States. Access to, or use of, the System, including the Sunset Health Service and any related information and materials, may be 
                            prohibited by law in certain jurisdictions. You are responsible for compliance with all applicable laws of the jurisdiction from which you are accessing the System. We make no representation that the 
                            information contained herein is appropriate or available for use in other locations.This Agreement and any contract between us, whether for use of the System or other purpose, and any non-contractual 
                            obligations (if any) arising out of or in connection with these terms and conditions or any such contract will be governed by the laws of the state of Delaware. The parties agree that the courts of 
                            Delaware have exclusive jurisdiction to settle any dispute arising out of or in connection with this Agreement (including a dispute regarding the existence, validity or termination of this Agreement 
                            or any non-contractual obligation arising out of or in connection with this Agreement). Notwithstanding the foregoing, we reserve the right to bring legal proceedings in any jurisdiction where we 
                            believe a breach of this Agreement has originated.
                        </p>
                        <h4 className="emp-h4  emp-font-bold">21. Survivorship</h4>
                        <p className="pp-p">
                            The following provisions shall survive termination of this Agreement: Clause 8 (Intellectual Property), Clause 12 (Limitation), Clause 15 (Indemnity), Clause 17 (Entire Agreement), Clause 18 
                            (Severability and waiver) and Clause 20 (Governing Law and Disputes).
                        </p>
                        <h4 className="emp-h4  emp-font-bold">22. Contact</h4>
                        <p className="pp-p">You can contact us via the following details: support@sunset.health</p>
                    </div>
                    <p className="pp-p">
                        Please note that we are unable to answer any enquiries requesting medical advice. Such enquiries should be addressed to an appropriate, qualified health practitioner.
                    </p>
                </div>
            </section>
        </Layout>
    )
}

export default TermsOfServicePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`